import styled from "styled-components";
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F1F5F7;
`;
export const ContentWrapper = styled.div`
  flex-grow: 1;
  margin-top: 164px;
  //  max-width: 1120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top: 1px solid #dadada;
  padding: 30px;
  background-color: #fff;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1120px;
  }
  .text-footer {
    color: var(--neutral-n-9, #434343);
    /* Caption / Caption - 12px - R - 0.4px */
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.25px;
  }
  .footer-rigth {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .policy {
    display: flex;
    gap: 20px;
  }
  .policy a {
    color: var(--neutral-n-9, #434343);
    text-align: right;

    /* Caption / Caption - 12px - R - 0.4px */
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.25px;
  }
  .social {
    display: flex;
    gap: 16px;
  }
  @media screen and (max-width: 768px) {
    .container {
      margin: 0px;
      padding: 12px 16px;
      flex-direction: column;
      gap: 20px;
    }
    .text-footer {
      font-size: 12px;
    }
    .footer-rigth {
      flex-direction: column;
      gap: 16px;
    }
  }
`;
