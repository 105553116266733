import React from "react";
import styled from "styled-components";
import NewNav from "../components/NewNav";
import Footer from "components/MainLayout/Footer";
import { Wrapper } from "components/MainLayout";
import HeaderPrivacy from "components/HeaderPrivacy";

const TitleComponent = styled.div`
  margin-top: 0;
  padding: 160px 0;
  background-color: #E5F2FF;
  & .title {
    margin: 20px 0px 25px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 47px;
    color: #000000;
    text-align: center;
  }
  
  & .description {
    margin: 0px 0px 20px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    text-align: center;
  }

`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  margin-top: 0;
`;

const CmsContent = styled.div`
  max-width: 1066px;
  margin-left: auto;
  margin-right: auto;
  padding: 48px 0;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #262626;
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {

  }
  ul {
    margin-left: 30px;
    list-style-type: circle;
    li {
      p {
        margin-bottom: 12px;
      }
    }
  }
`;

const AboutPage = () => {
  return (
    <Wrapper>
      <NewNav />
      <ContentWrapper>
        <div className="about">
          {/* <TitleComponent>
            <h1 className="title">About</h1>
            <div className="description">Welcome to Shorten – your simple and reliable URL shortening solution.</div>
          </TitleComponent> */}
          <HeaderPrivacy 
            title="About" 
            description="Welcome to Shorten – your simple and reliable URL shortening solution."
          />
          <CmsContent>
            <h3>Mission</h3>
            <div>
              <p>At Shorten, our mission is to provide users with an easy-to-use platform for managing and sharing URLs effectively. Whether you're promoting links on social media, sharing them in emails, or running marketing campaigns, we've got you covered.</p>
            </div>
            <br />
            <h3>What Sets Us Apart</h3>
            <div>
              <ul>
                <li><p><strong>User-Friendly Interface: </strong>Shorten offers an intuitive interface for seamless URL shortening.</p></li>
                <li><p><strong>Advanced Customization: </strong>Personalize your shortened URLs with custom aliases and settings.</p></li>
                <li><p><strong>Comprehensive Analytics: </strong>Gain insights into link performance with detailed analytics.</p></li>
                <li><p><strong>Secure and Reliable: </strong>We prioritize the security of your data, implementing industry-standard measures.</p></li>
                <li><p><strong>Flexible Plans: </strong>Choose from our free plan with 1000 URLs or upgrade to our premium plan for unlimited URL shortening and additional features.</p></li>
              </ul>
            </div>
            <br />
            <h3>Contact Us</h3>
            <div>
              <p>Have questions or need assistance? Our customer support team is ready to help. For a quick response, feel free to send us a message via our chatbox located at the bottom right corner of the page.</p>
            </div>
            <br />
            <p>Thank you for choosing Shorten for your URL shortening needs. We're dedicated to making your link management experience simple and efficient.</p>
          </CmsContent>
        </div>
      </ContentWrapper>
      <Footer/>
    </Wrapper>
  );
};

export default AboutPage;
