import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import LoginBg from "assets/images/left-logo.svg";
import LoginBgMb from "assets/images/left-logo-mobile.svg";
import LoadingBar from "../../components/Loading/LoadingBar";

const AuthWrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* Fill elements with full height */
  align-items: stretch;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f1f5f7;

  & a {
    color: ${(props) => props.theme.accentColor};
  }

  & .left-container {
    width: 53.52%;
    flex-grow: 1;
  }

  & .left-container img {
    /* make image fill container */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & .mobile {
    display: none;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    overflow: auto;
    & .left-container {
      width: 100%;
      height: auto;
    }
    & .left-container img {
      height: 100%;
      border-radius: 0px 0px 12px 12px;
    }
    & .right-container {
      width: 100%;
      height: calc(100% - 300px);
    }
    & .mobile {
      display: block;
    }
    & .desktop {
      display: none;
    }
  }
`;

const RightContainer = styled.div`
  flex-grow: 1;
  min-width: 480px;
  width: 46.48%;
  display: flex;
  padding: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  position: relative;
  font-weight: 500;
  & .content {
    //max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    text-align: center;

    /* downscale to 0.8 */
    transform: scale(0.8);
  }

  & .title {
    margin: 20px 0px 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 38.532px;
    line-height: 47px;
    color: #000000;
  }

  & form {
    //max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  & .logo img {
    width: 85px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px;
    width: 100%;
    min-width: auto;
    height: 100%;
    & .content {
      position: relative;
      top: -60px;
    }
  }
`;

const Custom = styled(LoadingBar)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.accentColor};
`;

const AuthWrapper = ({ children }) => {
  const { signupStatus, loginStatus } = useSelector((state) => state.auth);

  return (
    <AuthWrapperContainer>
      <div className="left-container">
        <img className="desktop" src={LoginBg} alt="Background" />
        <img className="mobile" src={LoginBgMb} alt="Background" />
      </div>
      <RightContainer>
        {(loginStatus === "loading" || signupStatus === "loading") && (
          <Custom />
        )}
        <div className="content">
          <Outlet />
        </div>
      </RightContainer>
    </AuthWrapperContainer>
  );
};

export default AuthWrapper;
