import { gql } from "@apollo/client";

// Get SHORTENs info for left side bar on dashboard
export const GET_ALL_SHORTENS_LEFTBAR_QUERY = gql`
  query getAllShortens {
    getAllShortens {
      id
      target
      shorten
      title
      createdAt
      hitCount
    }
  }
`;

export const SET_SELECTED_SHORTEN_QUERY = gql`
  query GetShortenInfo($id: ID!) {
    getShortenInfo(id: $id) {
      hits {
        id
        ip
        isp
        browser {
          name
        }
        os {
          name
        }
        location {
          name
          city
          country {
            code
            name
          }
        }
        createdAt
      }
      hitCount
      id
      target
      shorten
      title
      createdAt
      enableTracking
      byCountryGraph {
        count
        country {
          code
          name
        }
      }
    }
  }
`;

export const SET_SELECTED_HIT_QUERY = gql`
  query Hit($hitId: ID!) {
    hit(id: $hitId) {
      id
      ip
      type
      isp
      timezone {
        offset
        abbreviation
      }
      location {
        city
        postal
        country {
          name
          code
        }
      }
      browser {
        name
        version
      }
      os {
        name
        version
      }
      createdAt
    }
  }
`;

export const SET_SHORTEN_STATISTICS_QUERY = gql`
  query GetShortenStatistics {
    getShortenStatistics {
      totalLinks
      totalClicks
      numberOfClickedLast30d
    }
  }
`;