import React, { useEffect, useState } from "react";
import { Wrapper, GraphsWrapper, GraphWrapper } from "./ContainerBox";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import ChartColumn from "components/Chart/ChartColumn";
import moment from "moment";
import "./Performance.scss";
const Performance = ({ activeShorten }) => {
  const [data, setData] = useState([]);
  const [year, setYear] = useState('');
  const [listYear, setListYear] = useState([]);
  //if (!activeShorten?.hitCount) return null;
  const getData = (value) => {
    const result = Array.from({ length: 12 }, () => 0);
    activeShorten?.hits.forEach((item) => {
      const itemYear = moment(Number(item.createdAt)).format("YYYY");
      if (Number(itemYear) === Number(value)) {
        const itemMonth = moment(Number(item.createdAt)).format("MM");
        result[Number(itemMonth) - 1] += 1;
      }
    });
    setData(result);
  };
  const items = (
    <Menu
      selectedKeys={[year]}
      onClick={(e) => {
        setYear(e.key);
        getData(e.key);
      }}
    >
      {listYear?.map((item) => {
        return <Menu.Item key={item.value}>{item.value}</Menu.Item>;
      })}
    </Menu>
  );
  const createListYear = () => {
    const groupedData = {};
    activeShorten?.hits.forEach((item) => {
      const itemYear = moment(Number(item.createdAt)).format("YYYY");
      if (!groupedData[itemYear]) {
        groupedData[itemYear] = [];
      }
      groupedData[itemYear].push(item);
    });
    const outputArray = Object.keys(groupedData).map((key) => ({
      value: Number(key),
      data: groupedData[key],
    }));
    setListYear(outputArray);
    setYear(outputArray[0]?.value);
    getData(outputArray[0]?.value);
  };
  useEffect(() => {
    createListYear();
  }, [activeShorten]);
  return (
    <>
      {/* Graphs */}
      <Wrapper className="Performance">
        {/* Title */}
        <div className="title">
          <h1 className="heading">Performance</h1>
          <div className="rigth-title">
            {" "}
            <div className="click">
              <div className="circle" /> Click
            </div>{" "}
            <Dropdown overlay={items} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {year} <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
        <GraphsWrapper>
          <GraphWrapper className="details">
            <ChartColumn title={""} data={data} />
          </GraphWrapper>
        </GraphsWrapper>
      </Wrapper>
    </>
  );
};
export default Performance;
