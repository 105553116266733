import React from "react";
import { Wrapper } from "components/MainLayout";
import NewNav from "components/NewNav";
import Footer from "components/MainLayout/Footer";
import HeaderPrivacy from "components/HeaderPrivacy";
import "./index.scss";
import { data } from "./data";
export default function TermsAndConditions() {
  return (
    <Wrapper className="TermsAndConditions">
      <NewNav />
      <div className="content">
        <HeaderPrivacy title="Terms and Conditions" />
        <div className="container">
          <div className="content-container">
            {data.map((item, index) => {
              return (
                <div className="text" key={index}>
                  <p className="title">{item.title}</p>
                  {item.text.map((text, index) => {
                    return <p key={index}>{text}{item.strong ? <strong>{item.strong}</strong> : ''}</p>;
                  })}
                </div>
              );
            })}
            <div className="text">
              <p>By using Shorten, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using Shorten</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}
