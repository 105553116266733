import React, { useEffect, useContext } from "react";
import styled, {
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { ThemeContext } from "./context/ThemeContext";
import Router from "./Router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initializeUser } from "./features/authSlice";


import GlobalStyles from "./styles/GlobalStyles";

const Wrapper = styled.div`
  //height: 100vh;
  width: 100vw;

  color: rgb(85, 75, 85);
  background-color: rgb(255, 255, 255);

  * {
    box-sizing: border-box;
    //font-family: Montserrat, sans-serif;
  }
`;
const LoadingContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;

const App = () => {
  const { theme } = useContext(ThemeContext);
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const intialize = async () => {
      // 1. Check if token is already in local storage
      const token = localStorage.getItem("token");
      if (token) {
        dispatch(initializeUser());
      }
    };
    intialize();
  }, [dispatch]);

  return (
    <StyledThemeProvider edThemeProvider theme={theme}>
      <Wrapper
        style={{ height: pathname === "/auth" || pathname === "/dash/profile" ? "100vh" : "100%" }}
      >
        <GlobalStyles />
        <Router />
      </Wrapper>
    </StyledThemeProvider>
  );
};

export default App;
