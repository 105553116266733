import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import ActiveShortenInfo from "./ActiveShortenInfo";
import Graphs from "./Graphs";
import Performance from "./Performance";
import TableClicks from "./TableClicks";
import NewUserWelcomeImg from "../../../../assets/images/new-user-welcome.png";

const MainWrapperStyles = styled.div`
  height: 100%;
 // max-height: 95vh;
  padding-bottom: 20px;
  overflow-y: scroll;
  //padding: 14px 30px 0 0;
  flex-grow: 1;

  & .main-btn {
    padding: 7px;
  }

  /* Header Content */
  & .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  /* Search Bar */
  & .search-bar {
    padding: 13px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .search-bar input {
    /* flex-grow: 1; */
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 16px;
    line-height: 18px;
    color: #878787;
    outline: none;
    margin-left: 10px;
  }
  .highcharts-credits{
    display: none;
  }
`;

const NoShortensMessageWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const NoShortensMessageContainer = styled.div`
  max-width: 500px;
  margin-top: -100px;

  img {
    width: 70%;
  }
`;

const MainWrapper = () => {
  const { activeShorten, allShortens, shortenStatistics } = useSelector((state) => ({
    activeShorten: state?.dashboard?.activeShorten,
    allShortens: state?.dashboard?.userShortens,
    shortenStatistics: state?.dashboard.shortenStatistics,
  }));
  const activeHit = useSelector((state) => state?.dashboard?.activeHit);
  return (
    <MainWrapperStyles
      className="title-wrapper"
      initial="hidden"
      animate="shown"
      variants={{
        hidden: { y: "10%", opacity: 0 },
        shown: {
          y: 0,
          opacity: 1,
          transition: { delay: 1.3, duration: 0.3 },
        },
      }}
    >
      {activeShorten?.id ? (
        <>
          <ActiveShortenInfo activeShorten={activeShorten} shortenStatistics={shortenStatistics} />
          <Performance activeShorten={activeShorten}/>
          <Graphs activeShorten={activeShorten} />
          <TableClicks
            activeShorten={activeShorten}
            allShortens={allShortens}
            activeHit={activeHit}
          />
        </>
      ) : (
        <>
          <NoShortensMessageWrapper>
            <NoShortensMessageContainer>
              <img src={NewUserWelcomeImg} alt="Welcome" />
              <h2 style={{"margin-top": "20px"}}>Seems like you're new here!</h2>
              <h4>Get started by shorten your first link.</h4>
              <p>
                Click the <code>Shorten Link</code> on the top right corner. Add
                Some details and then click <code>Create</code> to see your
                first short URL in action
              </p>
              <p>
                Come back here for details about your first Shorten Link, like
                the visitors, etc.
              </p>
            </NoShortensMessageContainer>
          </NoShortensMessageWrapper>
        </>
      )}
    </MainWrapperStyles>
  );
};

export default MainWrapper;
