import React, { useEffect } from "react";
import { Routes, Route, Navigate,useNavigate ,useLocation} from "react-router-dom";
import { useSelector } from "react-redux";

// Pages
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Dashboard from "./pages/Manage/Dashboard/index";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsAndConditions from "pages/TermsAndConditions";
import PaymentStatusPage from "./pages/PaymentStatus";
import AboutPage from "./pages/About";

// Login, Signup
import AuthWrapper from "./pages/Auth/AuthWrapper";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import Forgot from "./pages/Auth/Forgot";
import Profile from "./pages/Manage/Profile/.";

import RedirectHandler from "./pages/RedirectHandler";

const Router = () => {
  const token = localStorage.getItem("token");
  // const navigate = useNavigate();
  const {pathname} = useLocation();
  const auth = useSelector((state) => state.auth);
  const isLoggedIn = useSelector((state) => !!state?.auth?.userInfo?.id);
  useEffect(()=>{
    if(isLoggedIn && pathname === '/'){
      // navigate('/dash');
    }
  },[isLoggedIn,pathname]);
  return (
    <>
      <Routes>
        {/* Base Paths */}
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/payment-status" element={<PaymentStatusPage />} />
        <Route path="/about" element={<AboutPage />} />
        {/* ManageRouter */}
        <Route path="/dash/*">
          { isLoggedIn ? (
            <>
              {/* Protected Routes */}
              <Route path="" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
            </>
          ) : (
            <>
              {
                (!token || auth && ["failed", "initialized"].includes(auth.status)) && 
                <Route path="*" element={<Navigate to="/auth" replace />} /> 
              }
            </>
          )}
        </Route>

        {/* Auth Router */}
        <Route path="/auth/*" element={<AuthWrapper />}>
          {isLoggedIn ? (
            <>
              <Route path="*" element={<Navigate to="/dash" replace />} />
            </>
          ) : (
            <>
              <Route path="" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="forgot" element={<Forgot />} />
            </>
          )}
        </Route>

        {/* All other routes */}
        <Route path="/*" element={<RedirectHandler />} />
      </Routes>
    </>
  );
};

export default Router;
