import React, { useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

export default function ChartColumn({
  title,
  subtitle,
  data,
  options: propOptions,
}) {
  useEffect(() => {});
  const options = {
    title: { text: title },
    subtitle: { text: subtitle || "" },
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
      labels: {
        style: {
          fontSize: 14,
          color: "#1F1F1F"
        }
      }
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: 10,
          color: "#434343"
        }
      }
    },
    tooltip: {
      backgroundColor: "#FF6B6B",
      borderRadius: 6,
      style: {
        color: "#fff",
      },
      className: "tooltip-chart",
      borderColor: "none"
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [{ name: "Click", data: data, color: "#3395FF", borderRadius: 4 }],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
