import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../apollo/client";
import {
  GET_ALL_SHORTENS_LEFTBAR_QUERY,
  SET_SELECTED_SHORTEN_QUERY,
  SET_SELECTED_HIT_QUERY,
  SET_SHORTEN_STATISTICS_QUERY,
} from "../queries/dashboard";
import toast from "react-hot-toast";

// const userShortenDummyData = {
//   id: 1,
//   target: "https://gmail.com",
//   shorten: "gmail",
//   title: "Gmail Link for share",
//   createdAt: "1640321836440",
//   hitCount: 1,
// };

// const userShortens = [].concat(...new Array(100).fill(userShortenDummyData));

export const getUserShortens = createAsyncThunk(
  "dashboard/getUserShortens",
  async (props) => {
    // Query the client
    const res = await client.query({
      query: GET_ALL_SHORTENS_LEFTBAR_QUERY,
      fetchPolicy: props && props.refetch && "no-cache",
    });

    // Delete typene
    delete res.data.getAllShortens.__typename;

    // Fullfill the action
    return res.data.getAllShortens;
  }
);

export const selectShorten = createAsyncThunk(
  "dashboard/selectShorten",
  async (id) => {
    // If no id is provided, return
    if (!id) return null;

    const res = await client.query({
      query: SET_SELECTED_SHORTEN_QUERY,
      variables: {
        id,
      },
    });

    // Fullfill the action
    return res.data.getShortenInfo;
  }
);

export const selectHit = createAsyncThunk("dashboard/selectHit", async (id) => {
  // If no id is provided, return
  if (!id) return null;

  const res = await client.query({
    query: SET_SELECTED_HIT_QUERY,
    variables: {
      hitId: id,
    },
  });

  // Fullfill the action
  return res.data.hit;
});

export const getShortenStatistics = createAsyncThunk(
  "dashboard/getShortenStatistics",
  async () => {
    const res = await client.query({
      query: SET_SHORTEN_STATISTICS_QUERY,
    });

    // Fullfill the action
    return res.data.getShortenStatistics;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    userShortens: [],
    activeShorten: null,
    shortenStatistics: null,
  },
  reducers: {
    selectHit: (state, action) => {
      state.selectedHit = action.payload;
    },
  },
  extraReducers: {
    [getUserShortens.fulfilled]: (state, action) => {
      state.userShortens = action.payload;
    },
    [selectShorten.fulfilled]: (state, action) => {
      state.activeShorten = action.payload;
    },
    [selectHit.fulfilled]: (state, action) => {
      state.activeHit = action.payload;
    },
    [getUserShortens.rejected]: (state, action) => {
      toast.error(`Query Failed: ${action.error.message}`);
    },
    [selectShorten.rejected]: (state, action) => {
      toast.error(`Query Failed: ${action.error.message}`);
    },
    [selectHit.rejected]: (state, action) => {
      toast.error(`Query Failed: ${action.error.message}`);
    },
    [getShortenStatistics.fulfilled]: (state, action) => {
      state.shortenStatistics = action.payload;
    },
    [getShortenStatistics.rejected]: (state, action) => {
      toast.error(`Query Failed: ${action.error.message}`);
    },
  },
});

// export const { selectHit } = dashboardSlice.actions;

export const selectUserShortens = (state) => state.dashboard.userShortens;

export default dashboardSlice.reducer;
