import moment from "moment";

export const getCompanyLogoLinkFromURI = (uri) => {
  if (!uri) return;
  const domain = getDomainFromURI(uri);
  const imgURI = `https://logo.clearbit.com/${domain}`;
  return imageExists(imgURI) ? imgURI : `https://${domain}/favicon.ico`;
};

export const imageExists = (URI) => {
  var http = new XMLHttpRequest();
  http.open("HEAD", URI, false);
  try {
    http.send();
  } catch (_) {
    return false;
  }
  return http.status !== 404;
};

export const getDomainFromURI = (uri) =>
  uri.replace(/(^\w+:|^)\/\//, "").split("/")[0];

export const getBackendURL = () => {
  const uri = process.env.REACT_APP_DEV_BACKEND_ENDPOINT || ``;

  // return URI, and relplace /undefined/ with /
  return uri.replace(/undefined/g, "/");
};

export const getHumanDateFromEpoch = (epoch) => {
  return (
    new Date(Number(epoch)).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }) || epoch
  );
};
export const getHumanTimeFromEpoch = (epoch) => {
  return (
    new Date(Number(epoch)).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }) || epoch
  );
};

export const flattenObject = (ob) => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const convertSearchParamsToObject = (str) => {
  let params = new URLSearchParams(str);
  const query = {};
  for (let [key, value] of params) {
    query[key] = value;
  }
  return query;
}

export const parsedUnixDatetime = (unix, format) => {
  const d = new Date(unix * 1000);
  return moment(d).format(format);
}

export const formatNumber = (value, prefix = '', fixed = 0) => {
  if (typeof value == 'undefined' || value === null) return ''
  let val = fixed > 0 ? (value / 1).toFixed(0) : value
  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')  
  if (fixed > 0) {
    val = parseFloat(val).toFixed(fixed)
  }
  return `${prefix}${val}`
}