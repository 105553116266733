import { gql } from "@apollo/client";

export const CREATE_SHORTEN_MUTATION = gql`
  mutation createShortenLink($target: String!, $shorten: String, $title: String, $enableTracking: Boolean, $expiryAt: String) {
    shortenLink(target: $target, shorten: $shorten, title: $title, enableTracking: $enableTracking, expiryAt: $expiryAt) {
      shorten
      fullLink
    }
  }
`;

export const GET_TARGET_BY_SHORTEN_MUTATION = gql`
  mutation getTargetByShorten($shorten: String!) {
    getTargetByShorten(shorten: $shorten)
  }
`;
