import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { ReactComponent as IconStar } from "assets/icons/FiveStar.svg";
import "swiper/css";
import "swiper/css/pagination";
import "./index.scss";
const dataCLone = {
  name: "Alex Wang",
  job: "Game Product Manager, Motive Studios EA",
  description:
    "A URL shortener, or a link shortener, simplifies long and complicated URLs into brief, comprehensible links.",
};

export default function OurCustomers() {
  const data = Array(6).fill(dataCLone);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://api.testimonial.asia/api/ourwidget/embed/643f4beb4a1e670013a3d3f7?projectId=65139d531dfcac19d11b3caf";
    script.async = true;

    const iframeContainer = document.getElementById(
      "ourWalloflove-iframe-643f4beb4a1e670013a3d3f7"
    );
    iframeContainer.appendChild(script);
    return () => {
      // Cleanup khi component bị unmounted
      iframeContainer.removeChild(script);
    };
  }, []);
  return (
    <div className="OurCustomers">
      {/* <div className="title">
        What <strong>Our Customers</strong> Are Saying
      </div>
      <div className="items">
        <Swiper
          spaceBetween={50}
          //slidesPerView={2}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="item">
                  <div className="item-icon">
                    <IconStar />
                  </div>
                  <div className="description">{item.description}</div>
                  <div className="item-footer">
                    <div className="name">{item.name}</div>
                    <div className="job">{item.job}</div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div> */}
      <div id="ourWalloflove-iframe-643f4beb4a1e670013a3d3f7"></div>
    </div>
  );
}
