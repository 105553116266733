export const data = [
  {
    title: "1. Introduction:",
    text: [
      'This Privacy Policy outlines the types of personal information that we collect, how we use and protect that information, and your rights regarding your personal data when you use our website (referred to as "Masterbranch Pte. Ltd."). By using the Website, you consent to the practices described in this Privacy Policy.',
    ],
  },
  {
    title: "2. Information Collection:",
    text: [
      "a. Personal Information: When you register for an account on the Website, we may collect personal information such as your name, email address, and password.",
      "b. Usage Information: We may collect non-personal information about your interaction with the Website, including the pages visited, links clicked, and referring website.",
      "c. Cookies: We may use cookies or similar technologies to enhance your experience on the Website. These cookies may collect non-personal information about your browsing habits.",
    ],
  },
  {
    title: "3. Use of Information:",
    text: [
      "a. Personal Information: We use your personal information to provide you with access to the Website's features, authenticate your account, and communicate with you regarding your account and the services offered.",
      "b. Usage Information: We use usage information to analyze and improve the functionality of the Website, personalize your experience, and optimize our services.",
      "c. Cookies: Cookies are used to enhance your browsing experience, remember your preferences, and provide relevant advertisements.",
    ],
  },
  {
    title: "4. Data Security:",
    text: [
      "We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.",
    ],
  },
  {
    title: "5. Third-Party Disclosure:",
    text: [
      "We do not sell, trade, or transfer your personal information to third parties unless required by law or for the purpose of providing the services you requested. However, non-personal information may be shared with third parties for analytics, advertising, or other legitimate purposes.",
    ],
  },
  {
    title: "6. Data Retention:",
    text: [
      "We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.",
    ],
  },
  {
    title: "7. Your Rights:",
    text: [
      "a. Access and Correction: You have the right to access and correct your personal information held by us. You can update your account information through the Website's settings or by contacting us directly.",
      "b. Data Portability: You have the right to request a copy of your personal data in a commonly used and machine-readable format.",
      "c. Data Deletion: You can request the deletion of your personal information, subject to any legal obligations or legitimate interests that require us to retain certain data.",
      "d. Marketing Communications: You can opt-out of receiving marketing communications from us by following the unsubscribe instructions provided in the communication or by contacting us directly.",
    ],
  },
  {
    title: "8. Children's Privacy:",
    text: [
      "The Website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will take steps to delete that information from our records.",
    ],
  },
  {
    title: "9. Changes to the Privacy Policy:",
    text: [
      "We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on the Website. Your continued use of the Website after such modifications constitutes your acceptance of the revised Privacy Policy.",
    ],
  },
  {
    title: "10. Contact Us:",
    text: [
      "If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us using the contact information provided on the Website.",
      "Company Name: Masterbranch Pte. Ltd.",
      "UEN: 202310047E",
      "Address: 1 TAMPINES NORTH DRIVE 1 #06-08 T-SPACE SINGAPORE (528559)",
      "Email: harry@masterbranch.co",
    ],
  },
];
