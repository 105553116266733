import React, { useEffect } from "react";
import styled from "styled-components";
import {
  getCompanyLogoLinkFromURI,
  getHumanDateFromEpoch,
  getHumanTimeFromEpoch,
  formatNumber,
} from "../../../../utils";

// import Icons
import NotFound from "../../../../assets/icons/not-found.svg";
import { ReactComponent as Globe } from "../../../../assets/icons/globe_color.svg";
import { ReactComponent as LinkUnShorten } from "../../../../assets/icons/linkunshorten.svg";
import { ReactComponent as Label } from "../../../../assets/icons/label.svg";
import { ReactComponent as Calendar } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link-2.svg";
import { ReactComponent as ChartIcon } from "assets/icons/chart.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/chart-success.svg";

import { Button, message } from "antd";
import "./ActiveShortenInfo.scss";
const URL = process.env.REACT_APP_DEV_BACKEND_ENDPOINT;
const DetailsWrapper = styled.div`
  & p {
    margin: 0;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1.3em;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.3em;

    /* identical to box height */

    /* Text-Secondary */

    color: rgba(0, 0, 0, 0.6);
  }

  & .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  & .row.top {
    justify-content: start;
    gap: 2%;
  }

  & .row.top img {
    height: 50px;
    margin-top: 5px;
  }

  & .row.top h1 {
    font-family:  Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    margin: 0;
  }
`;

const ActiveShortenInfo = ({ activeShorten, shortenStatistics = {} }) => {
  const [shorten, setShorten] = React.useState("");
  useEffect(()=>{
    const url = `${URL}/${activeShorten?.shorten}`;
    setShorten(url);
  },[activeShorten])
  return (
    <div className="ActiveShortenInfo">
      {/* Info */}
      <div className="info">
        {/* Title */}
        <div className="title">
          <div className="text">
            <h3 className="sub-heading">
              <a href={shorten} target="_blank">{ shorten }</a> <CopyIcon className="icon-copy" onClick={()=>{
                navigator.clipboard.writeText(shorten);
                message.success({content:"Copied to clipboard",duration:1});
              }} />
            </h3>
            <h1 className="heading"><a href={activeShorten?.target} target="_blank">{ activeShorten?.target }</a></h1>
          </div>
          {/* <div className="edit">
              <Button type="primary">Edit <EditIcon/></Button>
          </div> */}
        </div>
        {/* Main content */}
        {/* Details */}
        <div className="box-total">
            <div className="item">
               <div className="icon">
                    <LinkIcon/>
               </div>
               <div className="text">
                  <div className="title">Total Links</div>
                  <div className="number">{shortenStatistics && formatNumber(shortenStatistics.totalLinks) || 0}</div>
               </div>
            </div>
            <div className="item">
               <div className="icon">
                    <ChartIcon/>
               </div>
               <div className="text">
                  <div className="title">Total Clicks</div>
                  <div className="number">{shortenStatistics && formatNumber(shortenStatistics.totalClicks) || 0}</div>
               </div>
            </div>
            <div className="item">
               <div className="icon">
                    <SuccessIcon/>
               </div>
               <div className="text">
                  <div className="title">Clicks last 30 days</div>
                  <div className="number">{shortenStatistics && formatNumber(shortenStatistics.numberOfClickedLast30d) || 0}</div>
               </div>
            </div>
        </div>
        {/* <DetailsWrapper className="details">
    
          <div className="detail">
            <h3 className="title">Details</h3>
            <div className="detail-content shadowed">
              <div className="row top">
                <div className="detail-content-item">
                  <h1 className="text">
                    <img
                      src={getCompanyLogoLinkFromURI(activeShorten?.target)}
                      alt="Company Logo"
                      onError={(e) => {
                        e.target.src = NotFound;
                      }}
                    />
                  </h1>
                </div>
                <div className="detail-content-item">
                  <div className="header">
                    <Label className="icon" />
                    <span>Title</span>
                  </div>
                  <h1 className="text">{activeShorten?.title || "Untitled"}</h1>
                </div>
              </div>
              <div className="row">
                <div className="detail-content-item">
                  <div className="header">
                    <Globe className="icon" />
                    <span>Target</span>
                  </div>
                  <p className="text">{activeShorten?.target}</p>
                </div>
                <div className="detail-content-item">
                  <div className="header">
                    <LinkUnShorten className="icon" />
                    <span>Shorten Link</span>
                  </div>
                  <p
                    className="text"
                    onMouseOver={(e) => {
                      e.target.value = `${activeShorten?.shorten}`;
                    }}
                  >
                    {activeShorten?.shorten}
                  </p>
                </div>
                <div className="detail-content-item">
                  <div className="header">
                    <Calendar className="icon" />
                    <span>Created On</span>
                  </div>
                  <p className="text">
                    {getHumanDateFromEpoch(activeShorten?.createdAt)} |{" "}
                    {getHumanTimeFromEpoch(activeShorten?.createdAt)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </DetailsWrapper> */}
      </div>
    </div>
  );
};

export default ActiveShortenInfo;
