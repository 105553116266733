import React from "react";
import { Wrapper } from "components/MainLayout";
import NewNav from "components/NewNav";
import Footer from "components/MainLayout/Footer";
import HeaderPrivacy from "components/HeaderPrivacy";
import "./index.scss";
import { data } from "./data";
export default function PrivacyPolicy() {
  return (
    <Wrapper className="PrivacyPolicy">
      <NewNav />
      <div className="content">
        <HeaderPrivacy title="Privacy Policy" />
        <div className="container">
          <div className="content-container">
            {data.map((item, index) => {
              return (
                <div className="text" key={index}>
                  <p>{item.title}</p>
                  {item.text.map((text, index) => {
                    return <p>{text}</p>;
                  })}
                </div>
              );
            })}
            <div className="text">
              <p> Please note that this Privacy Policy should be reviewed in
              conjunction with the Terms and Conditions of the Website, as they
              are interconnected and govern your use of the Website and its
              services.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}
