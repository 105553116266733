import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import moment from "moment";
import "./TableClicks.scss";
const itemsPerPage = 5;

export default function TableClicks({ activeShorten, allShortens }) {
  const hits = activeShorten?.hits;
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const column = [
    {
      title: "Browser",
      dataIndex: "browser",
      key: "browser",
      render: (item) => {
        return <p>{item.name || "Chrome"}</p>;
      },
    },
    {
      title: "Platform",
      key: "platform",
      dataIndex: "os",
      render: (item) => {
        return (
          <p>
            {item?.name
              ? item?.name + (item?.version && (" | " + item?.version || "") || "")
              : "Unknown"}
          </p>
        );
      },
    },
    {
      title: "IP Address",
      key: "ip",
      dataIndex: "ip",
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      render: (item) => {
        return (
          <p>
            {item.city ? item?.city + ", " + item?.country?.name : "Unknown"}
          </p>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "createdAt",
      render: (item) => {
        return <p>{moment(Number(item)).format("DD/MM/YYYY hh:mm a")}</p>;
      },
    },
  ];

  const getData = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = hits.slice(indexOfFirstItem, indexOfLastItem);
    setData(currentItems);
  };
  useEffect(() => {
    getData();
  }, [hits,currentPage]);
  return (
    <div className="TableClicks" style={{ padding: "40px 0" }}>
      <Table columns={column} dataSource={data} pagination={false} />
      <div className="pagination" style={{ padding: "20px 0" }}>
        <Pagination
          current={currentPage}
          onChange={async (page) => {
            await setCurrentPage(page);
          }}
          pageSize={itemsPerPage}
          total={hits?.length}
        />
      </div>
    </div>
  );
}
