import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export const GET_USER = gql`
  query getUserInfo {
    getUserInfo {
      id
      email
      name
      domain
      secretKey
      agentId
      planName
      benefitKey
      isUnlimited
      maxUsage
      used
      createdAt
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation Signup($name: String!, $email: String!, $password: String!, $domain: String) {
    signup(name: $name, email: $email, password: $password, domain: $domain) {
      token
    }
  }
`;

export const GET_PAYMENT_STATUS = gql`
  mutation getPaymentStatus($sessionId: String!) {
    getPaymentStatus(sessionId: $sessionId) {
      status
      paymentStatus
      customerEmail
      total
      subtotal
      currency
      created
      currentPeriodEnd
    }
  }
`;