import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import client from "./apollo/client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import { Toaster } from "react-hot-toast";

import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { ConfigProvider } from "antd";

import store from "./app/store";
import "antd/dist/antd.variable.min.css";
TagManager.initialize({
  gtmId: "GTM-5WN5KK6P",
});
ConfigProvider.config({ theme: { primaryColor: "#007BFF" } });

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <ApolloProvider client={client}>
        <ThemeProvider>
          <BrowserRouter>
            <App />

            {/* Notifications - Toast Provider */}
            <Toaster
              toastOptions={{
                className: "",
                style: {
                  border: "1px solid #007BFF",
                  padding: "16px",
                  color: "#007BFF",
                },
              }}
            />
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
