import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import "./index.scss";
import NumberAnimation from "components/NumberAnimation";
export default function Numbers() {
  const numbers = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const handleScroll = () => {
    const element = numbers.current;
    if (element && element.getBoundingClientRect().top < window.innerHeight) {
      setIsShow(true);
    }
    setIsShow(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="numbers" ref={numbers}>
      <div className="container">
        <div className="items">
          <div className="item">
            <div className="number">
              <NumberAnimation dataNumber={1.5} isShow={isShow} />M
            </div>
            <div className="text">Links created</div>
          </div>
          <div className="item">
            <div className="number">
              <NumberAnimation dataNumber={20} />K
            </div>
            <div className="text">global users</div>
          </div>
          <div className="item">
            <div className="number">
              <NumberAnimation dataNumber={1.5} />M
            </div>
            <div className="text">click monthly</div>
          </div>
          <div className="item">
            <div className="number">
              <NumberAnimation dataNumber={4.9} />
              /5.0
            </div>
            <div className="text">review</div>
          </div>
        </div>
      </div>
    </div>
  );
}
