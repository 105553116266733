import React from 'react'
import styled from "styled-components";
import { useSelector } from "react-redux";

const TitleComponent = styled.div`
  margin-top: 70px;
  text-align: center;
  margin-bottom: 40px;
  & .title {
    margin: 20px 0px 10px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 47px;
    color: #000000;
    text-align: center;
  }
  
  & .description {
    margin: 0px 0px 20px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-align: center;
  }

`;

function PricingPage() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const customerDataProps = {};
  if (userInfo?.id) {
    customerDataProps["client-reference-id"] = `shorten-${userInfo?.id}`;
    customerDataProps["customer-email"] = userInfo?.email;
  }

  return (
    <div>
      <TitleComponent>
        <h1 className="title">Pricing</h1>
        <div className="description">Discover the perfect plan to supercharge your URL management on our Pricing Page</div>
      </TitleComponent>
      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID || ''}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''}
        {...customerDataProps}
      >
      </stripe-pricing-table>
    </div>
  );
}

export default PricingPage;
