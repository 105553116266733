export const data = [
  {
    title: "Acceptance of Terms:",
    text: [
      'By accessing and using our website (hereinafter referred to as "Shorten"), you agree to be bound by these Terms and Conditions. If you do not agree with any of the terms or conditions, please refrain from using Shorten Masterbranch',
    ],
  },
  {
    title: "Security Disclaimer:",
    text: [
      "Shortened links generated through the Website do not guarantee security. These links are intended solely for shortening purposes and do not provide inherent security features.",
    ],
    strong:  "If you require added security for your links, it is recommended to include a password or use other appropriate security measures."
  },
  {
    title: "Services Provided:",
    text: [
      "Shorten offers a service to shorten URLs, allowing users to generate shorter links for their convenience. Registered users have the option to log in and manage their shorten links. Additionally, users may choose to upgrade to a paid plan to manage an unlimited number of links.",
    ],
  },
  {
    title: "User Responsibilities:",
    text: [
      "Users are solely responsible for their use of the Website and the content they submit. You agree not to use the Website for any illegal, unauthorized, or malicious activities. Furthermore, you must not use the Website to distribute or access any content that violates applicable laws, infringes on intellectual property rights, or contains harmful or malicious code.",
    ],
  },
  {
    title: "User Accounts:",
    text: [
      "To access certain features of the Website, users may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must promptly notify us of any unauthorized use or security breach related to your account.",
    ],
  },
  {
    title: "Payment and Subscription:",
    text: [
      "Users may have the option to upgrade to a paid plan for managing an unlimited number of links. Payment terms, including pricing, billing cycles, and cancellation policies, will be clearly stated during the upgrade process. By subscribing to a paid plan, you agree to pay the specified fees associated with the selected plan.",
    ],
  },
  {
    title: "Intellectual Property:",
    text: [
      "Shorten and all its original content, including designs, logos, text, graphics, and software, are the intellectual property of the Website owner and are protected by applicable copyright and trademark laws. Users are prohibited from reproducing, distributing, or modifying any part of Shorten without prior written consent.",
    ],
  },
  {
    title: "Limitation of Liability:",
    text: [
      "Shorten owner shall not be liable for any damages, losses, or liabilities arising out of or in connection with the use or inability to use Shorten or its services. This includes but is not limited to direct or indirect damages, loss of data, or financial losses. Users acknowledge that they use Shorten at their own risk.",
    ],
  },
  {
    title: "Modification of Terms:",
    text: [
      "Shorten owner reserves the right to modify or update these Terms and Conditions at any time without prior notice. It is your responsibility to review the Terms periodically. Continued use of Shorten after any modifications implies your acceptance of the revised Terms.",
    ],
  },
  {
    title: "Governing Law:",
    text: [
      "These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction where Shorten owner is based, without giving effect to any principles of conflicts of law.",
    ],
  },
];
