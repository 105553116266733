import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as PaymentSuccessIcon } from "assets/icons/payment-success.svg";
import { convertSearchParamsToObject, parsedUnixDatetime } from '../utils';
import { paymentStatus } from '../features/authSlice';

const TitleComponent = styled.div`
  margin-top: 162px;
  text-align: center;
  & .icon {
    width: 40px;
    height: 40px;
  }
  & .title {
    margin: 10px 0px 10px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: #000000;
    text-align: center;
  }
  
  & .description {
    margin: 0px auto 20px auto;
    max-width: 445px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    text-align: center;
  }

`;

function PaymentStatus() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  let checkoutSessionId;
  if (search) {
    const searchQuery = convertSearchParamsToObject(search);
    checkoutSessionId = searchQuery && searchQuery["checkout-session-id"];
  }
  if (!checkoutSessionId) {
    navigate("/");
  }

  const [paymentResponse, setPaymentResponse] = useState(null);

  const getPaymentStatus = async () => {
      const res = await dispatch(
        paymentStatus({
          sessionId: checkoutSessionId,
        })
      );
      if (res && res.payload) {
        setPaymentResponse(res.payload);
      }
  };
  useEffect(() => {
    getPaymentStatus();
  }, []);

  const isPaymentSuccess = (paymentResponse && paymentResponse.status === "complete" && paymentResponse.paymentStatus === "paid") ? true : false;

  return (userInfo && userInfo.agentId) && isPaymentSuccess && (
    <div>
      <TitleComponent>
        <PaymentSuccessIcon className="icon" />
        <h3 className="title">🎉 Thank You for Choosing Us!</h3>
        <div className="description">Congratulations! Your payment of <strong>${paymentResponse.total}</strong> has been seamlessly processed for our <strong>{userInfo.planName}</strong>. Get ready to unlock a world of premium features! Your subscription is now active and set to expire on {parsedUnixDatetime(paymentResponse.currentPeriodEnd, 'MMM D, YYYY')}.</div>
      </TitleComponent>
    </div>
  ) || null;
}

export default PaymentStatus;
