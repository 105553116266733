import React from 'react'
import { ReactComponent as LikeIcon } from "assets/icons/Like.svg";
import { ReactComponent as SecureIcon } from "assets/icons/secure.svg";
import { ReactComponent as LinkIcon } from "assets/icons/IconLink.svg";

import "./index.scss";
export default function Card() {
  const data = [
    { title: "Secure", descripton: "It is fast and secure, our service has HTTPS protocol and data encryption" ,icon: <SecureIcon/>},
    { title: "Trackable Metrics", descripton: "Link shorteners offer tracking and analytics for campaign performance and audience insights." ,icon: <LikeIcon/>},
    { title: "Link shortening", descripton: "Create recognizable and trustworthy short links. Share with your audience for better brand visibility and higher conversion rates." ,icon: <LinkIcon/>},
]
  return (
    <div className='card-link-short'>
        <div className='items'>
            {data.map((item, index) => {
                return <div className='item' key={item.title}>
                    <div className='item-header'>
                         <div className='item-icon'>
                              {item.icon} 
                         </div>
                         <div className='title'>{item.title}</div>
                    </div>
                    <div className='description'>{item.descripton}</div>
                </div>
            })}
        </div>
    </div>
  )
}
