import styled from "styled-components";
import Chart from "../../../../components/Chart";
export const Wrapper = styled.div`
  margin-top: 15px;
  border-radius: 12px;
  border: 1px solid var(--neutral-n-5, #d9d9d9);
  padding: 20px;
  & .title {
    margin-top: 5px;
  }
`;

 export const StyledChart = styled(Chart)`
  /* width: 100%; */
  & g {
    margin: auto;
  }
`;

export const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
`;

export const GraphWrapper = styled.div`
  margin-top: 20px;
  overflow: scroll;
  height: 80%;
  width: 100%;
  //background-color: white;
`;