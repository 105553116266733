import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button } from "antd";
import { ReactComponent as ShortenIcon } from "assets/icons/shorten.svg";
import { useMutation } from "@apollo/client";

import { CREATE_SHORTEN_MUTATION } from "queries/shorten/.";
import { ReactComponent as TextSIcon } from "assets/icons/textS.svg";
import { ReactComponent as HeartIcon } from "assets/icons/heart.svg";
import toast from "react-hot-toast";
import Modal from "../../Modal";
import Success from "../../shorten/ShortenLink/Sucess";
import "./index.scss";
export default function LinkShort() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Mutation
  const [shortenLink, { loading, error, data }] = useMutation(
    CREATE_SHORTEN_MUTATION
  );
  useEffect(() => {
    if (data?.shortenLink) {
      setIsModalOpen(true);
    }
  }, [data]);
  if (error) {
    toast.error(error?.message);
    return `Error! ${error}`;
  }
  const onSubmit = ({ target, title, shorten, enableTracking }) => {
    // console.log(target, title, shorten, enableTracking);
    // ! Default expiryAt to 1 day
    const expiryAt = new Date(
      Date.now() + 1 * 24 * 60 * 60 * 1000
    ).toISOString();
    shortenLink({
      variables: { target, title, shorten, enableTracking, expiryAt },
    });
  };
  return (
    <div className="LinkShort">
      <div className="title">
        <div>
          <p>Link</p>
          <p className="bg-text">
            <span>
              <TextSIcon />
            </span>
            hortening
          </p>
        </div>
        <p>
          {" "}
          with One <div className="heart">Click <HeartIcon className="icon-heart"/></div>
        </p>
      </div>
      <div className="center">
        Paste any long URL to make it shareable, trackable, and customizable in
        just a few clicks, no account required!
      </div>
      <div className="input">
        <form className="form-input" onSubmit={handleSubmit(onSubmit)}>
          <input
            className="ant-input"
            type="url"
            name="target"
            placeholder="https://www.masterbranch.co"
            {...register("target", {
              required: "Please enter a valid url",
              pattern:
                /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
            })}
          />
          <div className="button-type" type="submit">
            <Button
              type="primary"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              <span>Shorten</span>
              <ShortenIcon />
            </Button>
          </div>
        </form>
        {errors?.target && (
          <p style={{ color: "red" }}>{errors?.target?.message}</p>
        )}
      </div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <Success data={data} />
      </Modal>
    </div>
  );
}
