import React from "react";
import { Collapse, Space } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";

import "./index.scss";
const { Panel } = Collapse;
export default function FAQs() {
  const data = [
    // {
    //   "title": "What is URL shortening, and how does it work?",
    //   "children": [
    //     "URL shortening is a technique to create a shortened version of a long URL, making it easier to share.",
    //     "Our service takes your lengthy URL and provides you with a shorter, more manageable link.",
    //     "When someone clicks on the short link, they are redirected to the original URL."
    //   ]
    // },
    // {
    //   "title": "Is there a limit to the number of URLs I can shorten?",
    //   "children": [
    //     "Yes, there is a limit to URL shortening, but worry not! Our free plan generously offers 1000 URLs, which is more than sufficient for most normal users. For unlimited URL shortening and additional perks, consider upgrading to our premium plan."
    //   ]
    // },
    // {
    //   "title": "Can I customize the shortened URLs?",
    //   "children": [
    //     "Yes, our service allows you to customize the shortened URLs.",
    //     "You can choose a custom alias or customize other settings to suit your preferences."
    //   ]
    // },
    // {
    //   "title": "How long will my shortened URLs be active?",
    //   "children": [
    //     "You have control over the expiration of your shortened URLs.",
    //     "You can set expiry dates to ensure the links are only active for a specific period."
    //   ]
    // },
    // {
    //   "title": "What kind of analytics do you provide?",
    //   "children": [
    //     "We offer detailed analytics that include click-through rates, geographical data of users, and referral sources.",
    //     "This information helps you understand the performance of your shortened URLs."
    //   ]
    // },
    // {
    //   "title": "Do you offer API integration support?",
    //   "children": [
    //     "Yes, we provide API integration support.",
    //     "Developers can integrate our URL shortening service into their applications using our API.",
    //     "Documentation is available to assist with the integration process."
    //   ]
    // },
    // {
    //   "title": "How can I get in touch with customer support?",
    //   "children": [
    //     "You can reach our customer support through [insert contact details].",
    //     "We're here to assist you with any questions or issues you may have."
    //   ]
    // },
    // {
    //   "title": "Is there a mobile app for your URL shortening service?",
    //   "children": [
    //     "Currently, we do not have a dedicated mobile app.",
    //     "But our website is optimized for mobile use.",
    //     "You can access and use our URL shortening service on any device with internet access."
    //   ]
    // },
    // {
    //   "title": "Is my data and information secure?",
    //   "children": [
    //     "Yes, we take security seriously.",
    //     "Our platform employs industry-standard security measures to protect your data and ensure a secure URL shortening experience."
    //   ]
    // },
    // {
    //   "title": "Can I use my own custom domain for shortened URLs?",
    //   "children": [
    //     "Yes, we offer the option to use your custom domain for shortened URLs.",
    //     "This allows you to maintain brand consistency and enhances the recognition of your links."
    //   ]
    // },
    // {
    //   "title": "How do I make a payment on your platform?",
    //   "children": [
    //     "To make a payment, navigate to the payment section during the checkout process.",
    //     "Enter your preferred payment details and follow the prompts.",
    //     "We use a secure payment gateway to process transactions efficiently.",
    //     "You will receive a confirmation once the payment is successfully processed."
    //   ]
    // },
    // {
    //   "title": "What payment methods do you support?",
    //   "children": [
    //     "We currently support major credit and debit cards.",
    //     "Additionally, various local payment methods may be available based on your location."
    //   ]
    // },
    // {
    //   "title": "Is my payment information secure?",
    //   "children": [
    //     "Yes, your payment information is highly secure.",
    //     "We use industry-standard security measures to protect your data during transactions.",
    //     "Sensitive payment information is encrypted, and we do not store it on our servers."
    //   ]
    // },
    // {
    //   "title": "Why choose us?",
    //   "children": [
    //     "User-friendly interface for effortless URL shortening.",
    //     "Comprehensive analytics to track the performance of your links.",
    //     "Advanced customization options, including custom aliases and settings.",
    //     "Secure and reliable service with industry-standard security measures.",
    //     "Flexible plans to suit your needs.",
    //     "Responsive customer support to assist you whenever needed."
    //   ]
    // }
    {
      "title": "What is a custom URL shortener?",
      "children": [
        "A custom URL shortener, sometimes referred to as a branded URL shortener, lets you brand your links.",
        "There are several benefits of branding your short links. Branded links build trust between your audience and your business, drive more clicks, give your audience a preview of where they are being taken and increase brand awareness.",
        "A link shortening service that includes custom short URLs is vital to improving audience engagement with your communications. A short URL is good, but a custom URL works every time.",
      ]
    },
    {
      "title": "Benefits of a short URL",
      "children": [
        "How many people can even remember a long web address, especially if it has tons of characters and symbols? A short URL can make your link more memorable. Not only does it allow people to easily recall and share your link with others, it can also dramatically improve traffic to your content.",
        "On a more practical side, a short URL is also easier to incorporate into your collateral – whether you’re looking to engage with your customers offline or online.",
        "Bitly is the best URL shortener for everyone, from influencers to small brands to large enterprises, who are looking for a simple way to create, track and manage their links."
      ]
    },
    {
      "title": "Why choose us?",
      "children": [
        "Whether you’re sharing one link or scan or millions, our platform was built to help you make every point of connection between your content and your audience ignite action.",
        "It’s why the most recognized brands in the world love our platform."
      ]
    }
  ];
  return (
    <div className="FAQs" id="FAQs">
      <div className="title">FAQs</div>
      <div className="content">
        <Space direction="vertical">
          {data.map((item, index) => {
            return (
              <Collapse
                key={index}
                bordered={false}
                expandIcon={({ isActive }) => (
                  <RightCircleOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition="right"
              >
                <Panel header={item.title} key={index}>
                  <div>
                    <ul>
                      {item.children.map((child, index) => {
                        return <li key={index}>{child}</li>;
                      })}
                    </ul>
                  </div>
                </Panel>
              </Collapse>
            );
          })}
        </Space>
      </div>
    </div>
  );
}
