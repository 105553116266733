import React from "react";
import {
  Wrapper,
  GraphsWrapper,
  StyledChart,
  GraphWrapper,
} from "./ContainerBox";

const Graphs = ({ activeShorten }) => {
  if (!activeShorten?.hitCount) return null;

  return (
    <>
      {/* Graphs */}
      <Wrapper>
        {/* Title */}
        <div className="title">
          {/* <h3 className="sub-heading">View Stats and</h3> */}
          <h1 className="heading">Insights</h1>
        </div>
        <GraphsWrapper>
          <GraphWrapper className="details">
            <StyledChart
              data={parseDataForCountryGraph(activeShorten?.byCountryGraph)}
              title="Hits By Country"
            />
          </GraphWrapper>
          <div className="vl"></div>
          <GraphWrapper className="details">
            <StyledChart
              data={parseDataForBrowserGraph(activeShorten?.hits)}
              title="Browsers Used"
            />
          </GraphWrapper>
        </GraphsWrapper>
      </Wrapper>
    </>
  );
};

const parseDataForCountryGraph = (data) => {
  return Object.keys(data).map((item) => ({
    name: data[item].country.name,
    y: data[item].count,
  }));
};

const parseDataForBrowserGraph = (data) => {
  let returner = {};

  data.forEach((hit) => {
    const browser = hit?.browser?.name;

    if (browser) {
      returner[browser] ? (returner[browser] += 1) : (returner[browser] = 1);
    } else {
      returner["unknown"]
        ? (returner["unknown"] += 1)
        : (returner["unknown"] = 1);
    }
  });
  // return [{ y: 1, name: "hi" }];
  // return data

  return Object.keys(returner).map((browserName) => ({
    name: browserName,
    y: returner[browserName],
  }));
};

export default Graphs;
