import React from "react";
import "./index.scss";
export default function HeaderPrivacy(props) {
  const { title, description } = props;
  return (
    <div className="HeaderPrivacy">
      <div className="container">
        <div className="header-container">
          <div className="title">{title}</div>
          <div className="day">{description || "Last updated: July 06, 2023"}</div>
        </div>
      </div>
    </div>
  );
}
