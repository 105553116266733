import React, { useState, useEffect } from "react";
import { ReactComponent as SortAscending } from "../../../assets/icons/sort-ascending.svg";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import styled from "styled-components";
import ListItem from "../../../components/ListItem";
import {
  getCompanyLogoLinkFromURI,
  getHumanDateFromEpoch,
  flattenObject,
} from "../../../utils";

// Icons and assets
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as Click } from "assets/icons/click.svg";
import { ReactComponent as Link } from "assets/icons/link.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search28.svg";
import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import { ReactComponent as FilterIcon } from "assets/icons/fillter.svg";
import { ReactComponent as ShortenIcon } from "assets/icons/shorten.svg";

import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import {
  getUserShortens,
  selectShorten,
  getShortenStatistics,
} from "../../../features/dashboardSlice";

import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_SHORTEN_MUTATION } from "queries/shorten/.";
const LeftWrapperStyles = styled.div`
  height: 100%;
  padding: 20px 16px;
  background-color: #fafafa;
  border-radius: 12px;

  //padding: 14px 30px 20px 0;
  max-width: 450px;

  & .main-btn {
    padding: 7px;
  }

  /* Header Content */
  & .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px;
  }

  /* Search Bar */
  & .search-bar {
    padding: 13px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  & .main-btn svg {
    width: 24px;
    margin-bottom: -6px;
  }

  & .search-bar input {
    flex-grow: 1;
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 16px;
    line-height: 18px;
    color: #878787;
    outline: none;
    margin-left: 10px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
  & .search-bar svg {
    height: 18px;
  }
  & .ant-btn {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 12px 16px;
    gap: 16px;
    border-radius: 8px;
    max-width: 122px;
    span {
      color: var(--neutral-n-2, #fafafa);
      text-align: center;
      /* Button / Button 2 - 16px - SB- 0.5px */
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
      text-transform: capitalize;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  & {
    overflow: auto;
    padding-bottom: 100px;
  }

  & .list {
    align-items: center;
  }
  .icon-feature {
    display: flex;
    gap: 20px;
  }
`;

const LeftWrapper = () => {
  const dispatch = useDispatch();
  const [render, setRender] = useState(false);
  const [shortenLink, { loading, error, data }] = useMutation(
    CREATE_SHORTEN_MUTATION
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { userShortens: rawUserShortens, activeShorten } = useSelector(
    (state) => state.dashboard
  );
  const [userShortens, setUserShortens] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [method, setMethod] = useState("createdAt");
  useEffect(() => {
    dispatch(getUserShortens({ refetch: true }));
  }, [dispatch,render]);

  useEffect(() => {
    dispatch(getShortenStatistics());
  }, [dispatch, render]);

  useEffect(() => {
    if (rawUserShortens.length !== 0) {
      dispatch(selectShorten(rawUserShortens[0]?.id));
    }
  }, [dispatch, rawUserShortens,render]);

  useEffect(() => {
    const formatForLeftBar = (shortens) => {
      if (shortens.length === 0) {
        return;
      }

      return shortens.map((shorten) => ({
        leftIcon: {
          hover: shorten.target,
          src: getCompanyLogoLinkFromURI(shorten?.target),
        },
        rightItem: {
          subtitleItems: [
            {
              icon: { src: <Calendar />, hover: "Created on" },
              text: getHumanDateFromEpoch(shorten.createdAt),
            },
          ],
          id: shorten.id,
          title: shorten.title || "Untitled",
          target: shorten.target,
          shorten: shorten.shorten,
          bylineItems: [
            {
              icon: {
                // src: <Globe />,
                hover: "Link",
              },
              text: shorten.target,
            },
            // {
            //   icon: {
            //     src: <Link />,
            //     hover: "Shorten",
            //   },
            //   text: shorten.shorten,
            // },
            {
              icon: {
                src: <Click />,
                hover: "Clicks",
              },
              text: shorten.hitCount,
            },
          ],
          moreButtonItems: [
            {
              text: "Edit",
              onClick: () => {
                "Edit Was Clicked";
              },
            },
          ],
        },
      }));
    };
    setUserShortens(formatForLeftBar(rawUserShortens));
  }, [dispatch, rawUserShortens,render]);

  const select = (id) => {
    dispatch(selectShorten(id));
  };

  return (
    <LeftWrapperStyles  className="title-wrapper">
      {/* Title */}
      <div className="header-content">
        <div className="title">
          <h1 className="heading">Links</h1>
          {/* <h3
            className="sub-heading"
            onClick={() => {
              setMethod("createdAt");
            }}
          >
            View past links
          </h3> */}
        </div>
        <div>
          <div className="icon-feature">
            <SearchIcon
              onClick={() => {
                setMethod("search");
              }}
            />
            <FilterIcon
              onClick={() => {
                setMethod("filter");
              }}
            />
            <SortIcon
              onClick={() => {
                setMethod("sort");
              }}
            />
          </div>
        </div>
      </div>
      {/* Header-content */}
      <div className="header-content">
        <div className="search-bar shadowed" hidden={method !== "createdAt"}>
          <input
            placeholder="Enter new link to shorten"
            {...register("target", {
              required: "Please enter a valid url",
              pattern:
                /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
            })}
          />
          <Button type="primary"  onClick={handleSubmit((data) =>{
             const { target } = data;
             console.log(target);
              shortenLink({
                variables: { target },
              });
              setRender(!render);
          })}>
            {" "}
            Create <ShortenIcon />
          </Button>
        </div>
        <div className="search-bar shadowed" hidden={method !== "search"}>
          <Filter />
          <input
            onChange={(e) => setFilterInput(e.target.value)}
            value={filterInput}
            type="text"
            placeholder="Filter..."
          />
        </div>
      </div>
      <div className="main-content">
        <div className="list">
          {userShortens &&
            userShortens.length > 0 &&
            userShortens
              ?.filter(
                (item) =>
                  item &&
                  Object.values(flattenObject(item))?.some((value) => {
                    return (
                      value &&
                      typeof value === "string" &&
                      value?.toLowerCase()?.includes(filterInput?.toLowerCase())
                    );
                  })
              )
              ?.map((item, index) => {
                const tick = 0.1;
                const relativeDelay = index * tick;

                const staggerVariants = {
                  hidden: { scale: 0.75, y: "100%", opacity: 0 },
                  shown: {
                    scale: 1,
                    y: 0,
                    opacity: 1,
                    transition: { delay: relativeDelay },
                  },
                };

                return (
                  <ListItem
                    initial="hidden"
                    animate="shown"
                    variants={staggerVariants}
                    key={item?.id}
                    onClick={() => select(item.rightItem.id)}
                    leftIcon={item.leftIcon}
                    rightItem={item.rightItem}
                    isActive={activeShorten?.id === item?.rightItem?.id}
                  />
                );
              })}
        </div>
      </div>
    </LeftWrapperStyles>
  );
};

export default LeftWrapper;
