import React from "react";
//import ShortenLink from "../components/shorten/ShortenLink";
import NewNav from "../components/NewNav";
import LinkShort from "components/Home/LinkShort";
import Card from "components/Home/Card";
import OurCustomers from "components/Home/OurCustomers";
import Numbers from "components/Home/Numbers";
import FAQs from "components/Home/FAQs";
import Footer from "components/MainLayout/Footer";
import { Wrapper,ContentWrapper } from "components/MainLayout";
// import PricingPage from "../components/PricingPage";


const Home = () => {
  return (
    <Wrapper>
      <NewNav />
      <ContentWrapper>
        {/* <ShortenLink /> */}
        <LinkShort />
        <Card />
        <OurCustomers />
        <Numbers />
        <FAQs />
      </ContentWrapper>
      <Footer/>
    </Wrapper>
  );
};

export default Home;
