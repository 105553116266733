import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import moment from "moment"
import NotFound from "../assets/icons/not-found.svg";

const ListItemStyles = styled(motion.div)`
  display: flex;
  cursor: pointer;
  width: calc(100% - 6px);
  background-color: #fafafa;
  align-self: center;
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
  border-radius: 12px;
  padding: 12px;
  margin: 15px auto;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  }

  p {
    margin: 0;
  }

  &.active {
    border: 1px solid var(--shorten-primary-p, #007bff);
    background: var(--shorten-primary-p-1, #e5f2ff);
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
  }
`;

const LeftItem = styled.div`
  margin-right: 5px !important;
  margin: auto;
  display: grid;
  place-content: center;

  & img {
    width: 45px;
  }

  & .rounded {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    /* cover the image */
    object-fit: cover;
  }
`;

const RightItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  .item svg {
    width: 15px;
    height: 15px;
    margin-bottom: -1px;
    display: inline-block;
  }
  .title {
    margin-bottom: 12px;
  }
  .title,
  .subtitle,
  .byline {
    display: block;
    text-overflow: ellipsis;

  }

  .byline {
    .item {
      p {
        text-overflow: ellipsis;
        max-width: 200px;
        max-height: 16px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .item,
  .item * {
    display: inline-block;
    margin-right: 3px;
  }

  .item p {
    font-size: 12px;
  }
  .title p {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    /* margin: -5px 0 -7px; */
    /* identical to box height */

    color: #007BFF;
  }
  .subtitle p {
    font-weight: 400;
    font-size: 14px;
    line-height: 9px;
    color: #8C8C8C;
    max-width: 100%;
  }
  .byline {
    p {
      font-size: 14px;
      color: #434343;
    }
  }
`;

// Structure
// const data = {
//   leftIcon: {
//     src,
//     hover,
//   },
//   rightItem: {
//     subtitleItems: [
//       {
//         icon: {
//           src,
//           hover,
//         },
//         text / link
//       }
//     ],
//     id,
//     title,
//     onClick,
//     bylineItems: [
//       {
//         icon: {
//           src,
//           hover
//         },
//         text / link
//       }
//     ],
//     moreButtonItems: [
//       {
//         text,
//         onClick
//       }
//     ]
//   },
// };
const URL = process.env.REACT_APP_DEV_BACKEND_ENDPOINT;

const ListItem = ({
  leftItem,
  leftIcon,
  rightElement,
  rightItem,
  onClick,
  isActive,
  ...props
}) => {
  const itemUrl = rightItem && rightItem.shorten && URL +"/"+ rightItem.shorten || "#";
  return (
    <ListItemStyles
      onClick={onClick}
      className={`${isActive && " active"}`}
      {...props}
    >
      {/* {isActive && ( */}
      <motion.div
        layoutId="outline"
        className="outline"
        initial={false}
        animate={{ borderColor: "#007BFF" }}
        transition={{
          type: "spring",
          stiffness: 500,
          damping: 30,
        }}
      />
      {/* )} */}
      {/* <LeftItem>
        {leftIcon ? (
          <>
            <img
              src={leftIcon?.src}
              alt={leftIcon?.hover}
              className={`${leftIcon?.rounded && "rounded"}`}
              onError={(e) => {
                e.target.src = NotFound;
              }}
            />
          </>
        ) : (
          leftItem
        )}
      </LeftItem> */}
      {/* Right Items */}
      {rightElement ? (
        rightElement
      ) : (
        <RightItem>
          {/* Title */}
          <div className="title">
            <p style={{maxWidth: '100%'}}>{itemUrl}</p>
          </div>
          {/* Byline */}
          <div className="byline">
            {rightItem.bylineItems.map((byline, idx) => (
              <div className="item" key={idx} title={byline.icon.hover}>
                {byline.icon.src}
                {byline.link ? (
                  <Link to={byline.link}></Link>
                ) : (
                  <p>{byline.text}</p>
                )}
              </div>
            ))}
          </div>
          {/* Subtitle */}
          <div className="subtitle">
            {rightItem.subtitleItems.map((subtitle, idx) => (
              <div className="item" key={idx} title={subtitle.icon.hover}>
                {/* {subtitle.icon.src} */}

                <p>Created {moment(subtitle.text).format("DD MMM YYYY LT")}</p>
              </div>
            ))}
          </div>
        </RightItem>
      )}
      {/* More Button */}
      {rightItem.moreButton && (
        <div className="moreButton">
          <svg
            width="2"
            height="10"
            viewBox="0 0 2 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1" cy="1" r="1" fill="#C4C4C4" />
            <circle cx="1" cy="5" r="1" fill="#C4C4C4" />
            <circle cx="1" cy="9" r="1" fill="#C4C4C4" />
          </svg>
        </div>
      )}
    </ListItemStyles>
  );
};

export default ListItem;
