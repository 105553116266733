import React from "react";

import { ReactComponent as FBIcon } from "assets/icons/facebook.svg";
import { ReactComponent as WhatappIcon } from "assets/icons/whatsapp.svg";
import { FooterWrapper } from "./index";
export default function Footer() {
  return (
    <FooterWrapper>
      <div className="container">
        <div className="text-footer">
          &copy; {new Date().getFullYear()} - The Shorten Ltd. All rights
          reserved.
        </div>
        <div className="footer-rigth">
          <div className="policy">
            <a href="/terms-conditions">Terms & Conditions</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
          <div className="social">
            <a href="/">
              <FBIcon />
            </a>
            <a href="/">
              <WhatappIcon />
            </a>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
}
