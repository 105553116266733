import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { Link, NavLink, useNavigate,useLocation} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Logo
import LogoWhite from "../assets/images/logo.png";
import IconRight from "../assets/icons/arrow-right.svg";
import User from "../assets/icons/user.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/logout.svg";
import { logoutUser } from "features/authSlice";

import "./NewNav.scss";

const NewNav = ({ overDark = true, transperant = false }) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();


  const dispatch = useDispatch();
  const [isScrolled, setIsScrolled] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  const userInfo = useSelector((state) => state.auth.userInfo);
  // Add menu state for mobile
  const [menuOpen, setMenuOpen] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 80) {
      // Số 100 là khoảng cách cuộn xuống mà bạn muốn header thay đổi màu
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      // clean up the event handler when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <div
          className="item-menu"
          onClick={() => {
            localStorage.removeItem("token");
            dispatch(logoutUser());
            window.location.href = process.env.REACT_APP_BASENAME || "";
          }}
        >
          <LogOutIcon />
          <p>Logout</p>
        </div>
      </Menu.Item>
    </Menu>
  );
  const onClickDropdown = ({ key }) => {};
  return (
    <div className={`header ${isScrolled ? 'white-header' : ''}`}  style={ pathname === '/dash' ? {position: 'relative', background: '#F5F5F5', borderBottom: '1px solid var(--shorten-primary-p, #007BFF)'} : {}}>
      <nav>
        <div
          className="logo-header"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            isLoggedIn ? navigate("/dash") : navigate("/")
          }}
        >
          <img src={LogoWhite} alt="log" />
          <span style={ transperant ? {color: "#FFF"} : {} }>Shorten</span>
        </div>
        <div
          className={"hamburger " + (menuOpen ? "toggle" : "")}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <ul className={"nav-links " + (menuOpen ? "open" : "")} style={pathname === '/dash' ? {"justify-content": "right", "margin-right": "12px"} : {}}>
          {/* <li className={menuOpen ? "fade" : ""}>
            <NavLink to="/">Shorten Link</NavLink>
          </li>
          <li className={menuOpen ? "fade" : ""}>
            <NavLink to="/dash">Dashboard</NavLink>
          </li> */}
          <li hidden={pathname === '/dash'} className={menuOpen ? "fade" : ""}>
            <NavLink to="/about" style={ transperant ? {color: "#FFF"} : {} }>About</NavLink>
          </li>
          <li className={menuOpen ? "fade" : ""}>
            <NavLink to="/pricing" style={ transperant ? {color: "#FFF"} : {} }>Pricing</NavLink>
          </li>
          <li hidden={pathname === '/dash'} className={menuOpen ? "fade" : ""}>
            <NavLink to="/#FAQs" onClick={()=>{
               var element = document.getElementById("FAQs");
               if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }} style={ transperant ? {color: "#FFF"} : {} }>FAQs</NavLink>
          </li>
          <li className={menuOpen ? "fade" : ""}>
            {isLoggedIn ? (
              <Link to="/dash/profile" className="user-icon">
                <img src={User} alt="Account" />
              </Link>
            ) : (
              <div
                className="button-login"
                onClick={() => {
                  navigate("/auth");
                }}
              >
                <span>Login</span>
                <img src={IconRight} alt="arrow-right" />
              </div>
            )}
          </li>
        </ul>
        <div className="right-header">
          {isLoggedIn ? (
            <div className="info-user">
              <img src={User} alt="Account" />
              <Link to="/dash/profile" className="user-icon" style={ transperant ? {color: "#FFF"} : {} }>
                {userInfo.name}
              </Link>
              <Dropdown overlay={menu} trigger={["click"]}>
                <a onClick={(e) => e.preventDefault()} style={ transperant ? {color: "#F1F1F1"} : {} }>
                  <CaretDownOutlined />
                </a>
              </Dropdown>
            </div>
          ) : (
            <div
              className="button-login"
              onClick={() => {
                navigate("/auth");
              }}
            >
              <span>Login</span>
              <img src={IconRight} alt="arrow-right" />
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default NewNav;
