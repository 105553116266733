import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

const NumberAnimation = ({ dataNumber = 0, isShow = false }) => {
  const [data, setData] = useState(0);
  const { number } = useSpring({
    number: data,
    from: { number: 0 },
    config: { duration: 3000 }, 
  });
  function roundTo1DecimalPlace(number) {
    const multipliedNumber = number * 100;

    const roundedNumber = Math.round(multipliedNumber);

    const result = roundedNumber / 100;

    return result;
  }
  useEffect(() => {
    setData(dataNumber);
  }, [dataNumber]);
  return (
    <animated.span>{number.to((n) => roundTo1DecimalPlace(n))}</animated.span>
  );
};

export default NumberAnimation;
